<template>
  <div>
    <div class="operate" style="margin-bottom: 15px" v-loading="loading">
      <el-row>
        <el-col :span="15">
          <h3>{{ courseInfo.course_name }}</h3>
        </el-col>
        <el-col :span="8" :offset="1" style="text-align: right;">
          <el-button-group>
            <el-button type="primary" :disabled="!($isPowers((priexRoute||'course') +'UnitCreate') && courseInfo.user_id+'' === userInfo.id+'')" size="small" @click="handleCommand('unitEdit')">新增章节</el-button>
            <el-button type="success" :disabled="!($isPowers((priexRoute||'course') +'ListCreate') && courseInfo.user_id+'' === userInfo.id+'')" size="small" @click="$handleRoute({course_id:$route.params.course_id},(priexRoute||'teachingCourse')+'ListCreate')">新增条目</el-button>
            <el-button type="primary" :disabled="courseInfo.user_id+'' !== userInfo.id+''" size="small" @click="$handleRoute({...$route.params},(priexRoute||'teachingCourse')+'Task')">新增作业</el-button>
            <!-- <el-button type="success" size="small" @click="$refs.table.toggleAllExpansion(isExpandAll = !isExpandAll)">{{ isExpandAll ? '隐藏条目' : '显示条目' }}</el-button> -->
            <el-button type="success" size="small" @click="changeExpand">{{ isExpandAll ? '隐藏条目' : '显示条目' }}</el-button>
          </el-button-group>
        </el-col>
      </el-row>
    </div>
    <com-table :columns="[
          {prop:'title',label:'章节/条目名称',},
          {prop:'create_date',label:'创建时间',align:'center'},
          {fixed:'right',label:'操作',width:'300' , slot:'action' , align:'center',hidden:courseInfo.user_id+'' !== userInfo.id+''},
      ]"
               :data="tableData"
               ref="table"
               :show-header="true"
               row-key="key"
              :page-size="100"
               :default-expand-all="isExpandAll"
               :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
               :edit-method="handleEditChange"
    >
      <template slot="action" slot-scope="{row}">
        <el-button-group>
          <template v-if="!(row.unit_id > 0)">
            <el-button type="primary" :disabled="!$isPowers((priexRoute||'course') +'ListCreate')" size="mini" @click="$handleRoute({...$route.params,unit_id:row.id}, (priexRoute||'teachingCourse')+'ListCreate')">新增条目</el-button>
            <el-button type="primary" :disabled="!$isPowers((priexRoute||'course') +'UnitEdit')" size="mini" @click="handleCommand('unitEdit' , {...row})">编辑</el-button>
            <el-button type="primary" :disabled="!$isPowers((priexRoute||'course') +'UnitDel')" size="mini" @click="$handleConfirm('你确定删除此章节吗？','/xapi/course.course/unitDel',{...$route.params,id:row.id} , ()=>{$refs.table.reset();})">删除</el-button>
          </template>
          <template v-else>
            <el-button type="primary" :disabled="!$isPowers((priexRoute||'course') +'ListEdit')" size="mini" @click="$handleRoute({list_id:row.id,course_id:$route.params.course_id} , (priexRoute||'teachingCourse')+'ListEdit')">编辑</el-button>
            <el-button type="primary" :disabled="!$isPowers((priexRoute||'course') +'ListDel')" size="mini" @click="$handleConfirm('你确定删除此条目吗？','/xapi/course.course/listDel',{...$route.params,id:row.id} , ()=>{$refs.table.reset();})">删除</el-button>
            <el-button type="primary" size="mini" @click="$handleRoute({...$route.params,list_id:row.id},(priexRoute||'teachingCourse')+'Task')">新增作业</el-button>
            <el-button type="primary" v-if="!courseInfo.is_plan && courseInfo.learn_mode==2" :disabled="!$isPowers((priexRoute||'course') +'ListDel')" size="mini"
             @click="$handleConfirm(row.is_open==1?'你确定关闭此条目吗？':'你确定开放此条目吗？','/xapi/course.course/updateField',{field: 'is_open',id:row.id,value:row.is_open==1?2:1},
             ()=>{$refs.table.reset()})">
             {{row.is_open==1?'关闭条目':'开放条目' }}</el-button>
            <!-- <el-button type="primary" v-if="!courseInfo.is_plan && courseInfo.learn_mode==2" :disabled="!$isPowers((priexRoute||'course') +'ListDel')" size="mini"
             @click="changeStatus(row)">
             {{row.is_open==1?'关闭条目':'开放条目' }}
            </el-button> -->

          </template>
        </el-button-group>
      </template>
    </com-table>
    <diy-dialog ref="dialog" :title="(dialogData.id ? '编辑' : '添加') + '章节'" width="650px" center>
      <unit-save ref="unitSave" :unit_id="dialogData.id"><template slot="after">&nbsp;</template></unit-save>
      <div slot="footer" style="display: flex;justify-content: space-between">
        <el-button @click="$refs.dialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('unitSave')">确 定</el-button>
      </div>
    </diy-dialog>
    <diy-dialog ref="listDialog" :title="(dialogData.id ? '编辑' : '添加') + '章节'" width="65vw" center>
      <list-save ref="listSave" :list_id="dialogData.id"></list-save>
      <div slot="footer" style="display: flex;justify-content: space-between">
        <el-button @click="$refs.listDialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('listSave')">确 定</el-button>
      </div>
    </diy-dialog>
  </div>
</template>

<script>
import DiyDialog from "@/components/com/diyDialog";
import UnitSave from "@/views/api/teaching/course/unitSave";
import ListSave from "@/views/api/teaching/course/listSave";
export default {
  components: {ListSave, UnitSave, DiyDialog},
  props:['priexRoute','course_id'],
  data(){
    return {
      loading:false,
      courseInfo:{},
      dialogData:{},
      isExpandAll:true,
      tableData: {
        url: "/xapi/course.course/unitList",
        // params: { ...this.$route.query },
      },
      title:'开放条目',
      isOpen:0
    };
  },
  // beforeDestroy(){
  //   localStorage.removeItem('isCourse')
  // },
  methods:{
    routeUpdate(){
      this.getDetail();
      // console.log(localStorage.getItem('isExpandAll'),"localStorage.getItem('isExpandAll')");
      if(localStorage.getItem('isExpandAll')){
        this.isExpandAll=localStorage.getItem('isExpandAll')=='false'?false:true
      }
      // console.log(this.tableData,"this.tableDatatableData");
      // this.$refs.table.toggleAllExpansion(this.isExpandAll = !this.isExpandAll)
    },
    // changeStatus(row){
    //   console.log(row,"wwwwwwww")
    //   this.isOpen=row.is_open
    //   this.$confirm(row.is_open==1?'你确定关闭此条目吗？':'你确定开放此条目吗？', '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }).then(() => {
    //       this.$http.post('/xapi/course.course/updateField' ,{field: 'is_open',id:row.id,value:row.is_open==1?2:1},).then(res=>{
    //         console.log(res,"resssssss")
    //         if(res.msg==='条目开放成功'){

    //         }
    //         this.$message({
    //         type: 'success',
    //         message: res.msg
    //       });
    //       })
          
    //     }).catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '已取消删除'
    //       });          
    //     });
    // },
    changeExpand(){
      this.isExpandAll = !this.isExpandAll
      // let isExpandAll=this.isExpandAll
        localStorage.setItem('isExpandAll',this.isExpandAll)
      // console.log(isExpandAll,"isExpandAllisExpandAll");
      this.$refs.table.toggleAllExpansion(this.isExpandAll)
    },
    getDetail(){
      if(this.course_id > 0){
        this.loading = true;
        // this.$http.post('/xapi/course.course/detail' ,{id:this.course_id}).then(({data,isOnlyField})=>{
        this.$http.post('/xapi/course.course/detail_simplen' ,{id:this.course_id}).then(({data,isOnlyField})=>{
          this.courseInfo = data;
          this.loading = false;

          if(this.courseInfo.is_plan){
            localStorage.setItem('isPlan', this.courseInfo.is_plan)
            console.log("存进去isPlan");
          }else{
            localStorage.setItem('isCourse', 1)
            console.log("存进去courese");
          }
          
        }).finally(()=>{
          this.loading = false;
        });
      }else{
        this.courseInfo = {};
      }
    },
    async handleCommand(command , data){
      if(command === 'unitEdit'){
        this.dialogData = data || {};
        this.$refs.dialog.visibled(true);
      }
      if(command === 'unitSave'){
        this.$refs.unitSave && this.$refs.unitSave.handleSubmit(()=>{
          this.$refs.dialog.visibled(false);
          this.$refs.table.reset();
        })
      }
      if(command === 'listEdit'){
        this.dialogData = data || {};
        this.$refs.listDialog.visibled(true);
      }
      if(command === 'listEdit'){
        this.$refs.listSave && this.$refs.listSave.handleSubmit(()=>{
          this.$refs.listDialog.visibled(false);
          this.$refs.table.reset();
        })
      }
    },
    handleEditChange(v , ov , {row , column}){
      this.$http.post(row.unit_id ? '/xapi/course.course/listUpdateField' : '/xapi/course.course/unitUpdateField',{
        field:column.prop,
        id:row.id,
        value:v
      }).catch(()=>{
        row[column.prop] = ov;
      })
    }
  }
}
</script>

<style scoped>

</style>